<template>
  <main class="px-10">
    <div class="flex items-center justify-end mb-6">
      <TamButton @click="showDialog = true" color="info" label="Invite team member" />
    </div>
    <div class="relative overflow-x-auto sm:rounded-lg border">
      <table class="w-full text-sm text-left text-gray-500">
        <thead class="text-xs text-gray-700 uppercase bg-light-background">
          <tr>
            <th scope="col" class="px-6 py-3" v-for="item in tableHead" :key="item">{{ item }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in teamMembers?.members" :key="item.uuid" class="bg-white border-b hover:bg-gray-50">
            <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap ">{{ item.name }}</td>

            <td class="px-6 py-4">{{ item.email }}</td>
            <td class="px-6 py-4 ">{{ item.role_at_company }}</td>
            <td class="px-6 py-4 text-brand-black font-bold ">
              {{ item.role }}
              <!-- <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton class=" inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-brand-primary">
                    {{ item.role }}
                    <ChevronDownIcon class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div class="py-1">
                      <MenuItem v-slot="{ active }">
                        <a
                          href="#"
                          @click="changeStatus(item.uuid)"
                          :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                          >{{ item.role }}</a
                        >
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu> -->
            </td>
            <!-- <td class="px-6 py-4 text-right">
              <Menu as="div" class="relative inline-block text-left">
                <div>
                  <MenuButton class="inline-flex w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-gray-700">
                    <More fill="black" />
                  </MenuButton>
                </div>

                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div class="py-1 px-2">
                      <MenuItem>
                        <a
                          href="#"
                          @click="$router.push(`/edit-job/${item.uuid}`)"
                          class="block px-4 py-2 hover:bg-brand-hover hover:rounded-md text-black"
                          >Edit</a
                        >
                      </MenuItem>
                      <MenuItem>
                        <a href="#" class="block px-4 py-2 hover:bg-brand-hover hover:rounded-md text-black">Share</a>
                      </MenuItem>
                      <MenuItem>
                        <a href="#" @click.prevent="deleteJob(item.uuid)" class="block px-4 py-2 hover:bg-brand-hover hover:rounded-md text-red-600"
                          >Delete</a
                        >
                      </MenuItem>
                    </div>
                  </MenuItems>
                </transition>
              </Menu>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>

    <div class="mt-10" id="invite">
      <div class="mb-6">
        <p class="text-2xl font-normal font-cooper">Invites</p>
        <p class="text-opacity-70 text-brand-black">Invited team members will receive an email. Invites expire after seven (7) days.</p>
      </div>
      <div>
        <div v-if="!hasPendinInvites">
          <Empty title="There are no invites available." text="You can invte team member's by using the invite button above." />
        </div>
        <div class="relative overflow-x-auto sm:rounded-lg border" v-else>
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs text-gray-700 uppercase bg-light-background">
              <tr>
                <th scope="col" class="px-6 py-3" v-for="item in inviteTableHead" :key="item">{{ item }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in teamMembers?.invites" :key="item.uuid" class="bg-white border-b hover:bg-gray-50">
                <td scope="row" class="px-6 py-4 font-medium text-gray-900 dark:text-white whitespace-nowrap ">{{ item.name }}</td>

                <td class="px-6 py-4">{{ item.email }}</td>
                <td class="px-6 py-4 text-right text-brand-success "><div class="cursor-pointer" @click="resend(item.email)">
                  <span v-if="!teamLoaders[item.email].loading">Resend</span> <loading :is-loading="teamLoaders[item.email].loading" />
                </div></td>
                <td class="px-6 py-4 text-right text-brand-danger " ><div class="cursor-pointer" @click="revoke(item.uuid)">Revoke invite</div></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <TamModal v-show="showDialog" size="xl">
      <template #header>
        <div class="flex justify-between items-center">
          <p class="text-xl font-medium">Invite Team Member</p>
          <TamButton @click="showDialog = false" :icon="Close" />
        </div>
      </template>
      <template #body>
        <div id="modal-body" class="gap-y-2">
          <div class="mb-4 flex flex-col lg:grid gap-x-4  grid-cols-2" >
          <div class="pt-4 flex flex-col">
            <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">First name</label>

            <input
              v-model="details.first_name"
              type="text"
              id="email-address-icon"
              class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button block w-full p-3"
            />
          </div>
          <div class="pt-4 flex flex-col">
            <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">Last name</label>

            <input
              v-model="details.last_name"
              type="text"
              id="email-address-icon"
              class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button block w-full p-3"
            />
          </div>
          <div class="pt-4 flex flex-col">
            <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">Work email address</label>

            <input
              v-model="details.email"
              type="email"
              id="email-address-icon"
              class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button focus:border-0 block w-full p-3"
            />
          </div>
          <div class="pt-4 flex flex-col">
              <label for="email-address-icon" class="block mb-2 text-sm font-medium text-[#090E11]/70">Role At Company</label>

              <select
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button block w-full p-3"
                name="role_at_company"
                id="role_at_company"
                v-model="details.role_at_company"
              >
                <option selected disabled>Please Select</option>
                <option v-for="item in enums?.company_roles" :key="item.key" :value="item.key">{{ item.value }}</option>
              </select>
            </div>

        </div>
        <div class="mb-4 flex flex-col w-full">
              <label for="role" class="block mb-2 text-sm font-medium text-[#090E11]/70">Permission</label>

              <select
                class="bg-[#F2F2F2] border-0 text-[#090E11] text-sm rounded-lg focus:ring-brand-button block w-full p-3"
                name="role"
                id="role"
                v-model="details.role"
              >
                <option selected disabled>Please Select</option>
                <option v-for="item in enums?.employer_roles" :key="item.key" :value="item.key">{{ item.value }}</option>
              </select>
            </div>
        </div>
      </template>
      <template #footer>
        <tam-button class="w-full" :loading="loading" @click="invite" type="submit" color="info" label="Send Invite" />
      </template>
    </TamModal>
  </main>
</template>

<script setup>
import TamTable from '@/components/TamTable.vue';
import TamButton from '@/components/TamButton.vue';
import TamModal from '@/components/TamModal.vue';
import Empty from '../components/Empty.vue';
import Loading from '../components/Loading.vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/20/solid';
import More from '@/assets/icons/more.svg?inline';
import { ref, onMounted, computed } from 'vue';
import { Close } from '@/icons.js';
import { useStore } from 'vuex';
import { errorMessage, alert } from '@/utils/helper';
import { useToast } from 'vue-toastification';

const store = useStore();
const toast = useToast();

const teamMembers = ref({
  members: [],
  invites: [],
});
const showDialog = ref(false);
const loading = ref(false);
const tableHead = ref(['TEAM MEMBER', 'EMAIL ADDRESS', 'ROLE', 'PERMISSIONS']);
const inviteTableHead = ref(['FULL NAME', 'EMAIL ADDRESS', '', '']);
const details = ref({
  first_name: '',
  last_name: '',
  email: '',
  role: '',
  role_at_company: ''
});
const enums = computed(() => store.getters['global/getEnumDetails']);
const teamLoaders = ref({});
const hasPendinInvites = computed(() => teamMembers.value.invites?.length > 0);

async function revoke(id) {
  try {
    const { message } = await store.dispatch('auth/revokeTeamMember', id);
    alert(message, 'success');
    await store.dispatch('auth/getTeamMembers', { forceReload: true });
    teamMembers.value = store.getters['auth/teamMembers'];
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  }
}

const setTeamLoadingState = (email) => {
  teamLoaders.value[email] = { loading: false };
};

const resend = async(email) => {
  try {
    if (teamLoaders.value[email].loading === true) return;
    teamLoaders.value[email].loading = true
    const { message } = await store.dispatch('auth/resendTeamInvite', {email});
    alert(message, 'success');
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    teamLoaders.value[email].loading= false;
  }
}

async function invite() {
  try {
    loading.value = true;
    const { message } = await store.dispatch('auth/inviteTeamMember', details.value);
    alert(message, 'success');
    showDialog.value = false;
    await store.dispatch('auth/getTeamMembers', { forceReload: true });
    teamMembers.value = store.getters['auth/teamMembers'];
    teamMembers.value.invites?.map((member) => setTeamLoadingState(member.email));
  } catch (error) {
    if (error.response && error.response.status === 422) {
    }
    alert(errorMessage(error), 'error');
  } finally {
    loading.value = false;
  }
}

onMounted(async () => {
  try {
    if (!Array.isArray(store.getters['global/getEmployerRoles'])) {
      await store.dispatch('global/getEnums');
    }
    await store.dispatch('auth/getTeamMembers', { forceReload: true });
    teamMembers.value = store.getters['auth/teamMembers'];
    teamMembers.value.invites?.map((member) => setTeamLoadingState(member.email));
  } catch (error) {
    alert(errorMessage(error), 'error');
  }
});
</script>
