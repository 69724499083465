<template>
  <main class="antialiased z-50 fixed inset-0 font-GTWalsheimPro overflow-x-hidden">
    <div class="relative px-4 min-h-screen md:flex md:items-center md:justify-center">
      <div class="w-full h-full fixed inset-0 bg-black/70"></div>
      <div class="inset-x-0 bottom-0 z-50 md:relative fixed w-full max-h-[520px] h-full overflow-y-scroll" :class="[appliedSize, appliedHeight]">
        <div class="px-3 py-5 w-full bg-white md:rounded rounded-b-none rounded-lg relative">
          <div class="top-0 sticky z-50 bg-white"><slot name="header" /></div>
          <div><slot name="body" /></div>
          <div><slot name="footer" /></div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
const props = defineProps({
  size: { type: String },
  height: { type: String },
});
const sizes = {
  md: 'md:max-w-md',
  lg: 'md:max-w-lg',
  xl: 'md:max-w-xl',
  '3xl': 'md:max-w-3xl',
  '4xl': 'md:max-w-4xl',
  '5xl': 'md:max-w-5xl',
  '6xl': 'md:max-w-6xl',
};
let appliedSize = sizes[props.size] || sizes['md'];

const heights = {
  min: 'md:max-h-min',
  max: 'md:max-h-max',
  full: 'md:max-h-full',
};
let appliedHeight = heights[props.height] || heights['max'];
</script>

<style scoped></style>
